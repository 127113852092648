import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import styles from '../styles/Home.module.css'

import Layout from '../components/Layout'

import Year25 from "../public/images/year25_3.png"
import Placeholder from "../public/images/placeholder2.jpg"

export default function Home() {
  return (
    <Layout name="Home" noHeader>
      <header className={`h-screen ${styles.hero}`}>
        <div className="h-screen bg-opacity-60 bg-black flex items-center justify-center">
          <div className="mx-2 text-center">
            <h1 className="text-gray-500 font-bold text-4xl xs:text-5xl md:text-6xl">
              <span className="text-white">Anglo-Indian Association Of Canada</span>
            </h1>
            <h2 className="text-gray-500 font-semibold text-2xl xs:text-3xl md:text-4xl mt-4">
              <span className="text-gray-300">Increasing Awareness Of Anglo-Indians</span>
            </h2>
          </div>
        </div>
      </header>

      <div className="flex p-10 flex-col items-center md:flex-row lg:p-20 xl:p-40 items-left bg-yellow-50">
        <div className="flex-col w-4/5 text-center md:text-left mb-4 md:mb-0">
          <h1 className="text-black font-bold text-2xl xs:text-3xl md:text-4xl">Note From The President</h1>
          <p className="mt-4 w-full md:w-3/4 text-lg text-gray-600">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </div>

        <div className="flex-col w-full md:w-1/2">
          <Image className="rounded" src={Year25} alt="Placeholder" objectFit="cover" objectPosition="center" width={800} height={800} quality={100} />
        </div>
      </div>

      <div className="flex p-10 flex-col items-center md:flex-row-reverse lg:p-20 xl:p-40 items-left bg-blue-100">
        <div className="flex flex-col w-4/5 text-center md:text-right mb-4 md:mb-0 items-end">
          <h1 className="text-black font-bold text-2xl xs:text-3xl md:text-4xl">Who We Are</h1>
          <p className="mt-4 w-full md:w-3/4 text-lg text-gray-600">
            The Anglo-Indian Association of Canada is primarily a social club for all. Community events are held monthly and larger social events are 
            held periodically over the year.
          </p>
        </div>

        <div className="flex-col w-full md:w-2/3">
          <Image className="rounded-lg" src={Placeholder} alt="Placeholder" objectFit="cover" objectPosition="center" width={800} height={500} />
        </div>
      </div>
    </Layout>
  )
}
