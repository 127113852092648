import Header from '../components/header'
import Footer from '../components/footer'

import Head from 'next/head'

import { motion } from 'framer-motion'

const pageFadeIn = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1
    },
    exit: {
        opacity: 0
    },
    transition: {
        duration: 0.6,
        type: "easeInOut"
    }
};

const InsideContentWrapper = ({ noHeader, name, children }) => (
    <>
        {!noHeader &&
            <header className="md:flex-row items-center justify-between px-10 py-4 lg:px-20 xl:px-60 xl:py-10 bg-blue-100">
                <h1 className="text-black text-center lg:text-left text-4xl font-bold">{name}</h1>
            </header>
        }

        <main className="min-h-screen bg-blue-50">
            {children}
        </main>
    </>
);

export default function Layout({ children, noHeader, name, removeFade }) {
    return (
        <div className="flex flex-col">
            <Head>
                <title>{name} | Anglo-Indian Association of Canada</title>
                <meta name="description" content="The website representing the Anglo-Indian Association of Canada." />
                <link rel="icon" href="/favicon.ico" />

                <meta property="og:title" content={`${name} | Anglo-Indian Association of Canada`} />
                <meta property="og:description" content="The website representing the Anglo-Indian Association of Canada." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="/images/aiac-banner.png" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="719" />
                <meta property="og:image:height" content="176" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content={`${name} | Anglo-Indian Association of Canada`} />
                <meta property="twitter:description" content="The website representing the Anglo-Indian Association of Canada." />
                <meta property="twitter:image:src" content="/images/aiac-banner.png" />
            </Head>

            <Header />

            {/* Only shows page fade if user asks, important since some pages have a weird bounce effect with a motion.div wrapper */}
            {removeFade ?
                (
                    <InsideContentWrapper name={name} noHeader={noHeader}>
                        {children}
                    </InsideContentWrapper>
                )
                :
                (
                    <motion.div
                        variants={pageFadeIn}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition="transition"
                        layout
                    >
                        <InsideContentWrapper name={name} noHeader={noHeader}>
                            {children}
                        </InsideContentWrapper>
                    </motion.div>
                )
            }

            <Footer />
        </div >
    );
}